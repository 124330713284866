var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Guest',[_c('div',{staticClass:"w-full position-relative overlow-hidden",style:({
      background:
        'radial-gradient(74.4% 244.81% at 98.33% 95.87%, #F5F8E5 0%, rgba(245, 248, 229, 0) 100%), radial-gradient(52.95% 214.76% at 96.74% 6.85%, #E5F8E8 0%, rgba(229, 248, 232, 0) 100%), radial-gradient(46.5% 154.64% at 3.4% 96.43%, #D7F3DA 6.45%, rgba(215, 243, 240, 0) 100%), radial-gradient(45.44% 177.99% at 1.67% 4.62%, #DDD7FA 18.93%, rgba(218, 213, 244, 0) 98.96%), #FFFFFF',
      marginTop: '58px',
      height: 'calc(100vh - 58px)',
    })},[_c('div',{staticClass:"margin-x-auto position-relative h-full",style:({ maxWidth: '1800px' })},[_c('div',{class:{
          'max-w-400 position-relative padding-x-4 margin-x-auto':
            _vm.$vuetify.breakpoint.width < 1060,
          'max-w-460 position-absolute':
            _vm.$vuetify.breakpoint.width >= 1060 &&
            _vm.$vuetify.breakpoint.width < 1280,
          'max-w-580 position-absolute': _vm.$vuetify.breakpoint.width >= 1280,
        },style:({
          top: '45vh',
          transform: 'translateY(-50%)',
          left: _vm.$vuetify.breakpoint.width >= 1060 ? '10vw' : '0',
        })},[(_vm.$vuetify.breakpoint.smAndDown)?[_c('h1',{staticClass:"font-40 leading-48 margin-b-3"},[_vm._v(" Download the driver app! ")]),_c('p',{staticClass:"font-16 leading-20 margin-b-10"},[_vm._v(" Thanks for signing up! Download the Busify driver app and log in to see your assigned trips. ")])]:[_c('h1',{staticClass:"font-48 leading-56 margin-b-3"},[_vm._v(" Download the driver app! ")]),_c('p',{staticClass:"font-18 leading-24 margin-b-10"},[_vm._v(" Thanks for signing up! Download the Busify driver app and log in to see your assigned trips. ")])],_c('div',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.width >= 1060 ? 'max-w-500' : 'max-w-340'},[_c('a',{staticClass:"margin-r-5 w-full",attrs:{"id":"download-app-google-play-link","href":_vm.googlePlayUrl}},[_c('GooglePlay',{staticClass:"cursor-pointer w-full"})],1),_c('a',{staticClass:"w-full",attrs:{"id":"download-app-app-store-link","href":_vm.appStoreUrl}},[_c('AppStore',{staticClass:"cursor-pointer w-full"})],1)])],2),(_vm.$vuetify.breakpoint.width >= 1060)?_c('div',{staticClass:"position-absolute w-one-half h-full d-flex align-center justify-center right-0 top-0 overflow-hidden",style:({ maxHeight: '100vh' })},[_c('div',{style:({ maxHeight: '100vh', maxWidth: 'calc(100vw - 550px)' })},[_c('img',{staticClass:"w-full h-full",attrs:{"src":require("@/assets/images/DriverPage.webp")}})])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }