
import { Component, Vue } from 'vue-property-decorator'
import Guest from '@/layouts/Guest.vue'
import GooglePlay from '@/assets/images/GooglePlay.vue'
import AppStore from '@/assets/images/AppStore.vue'
import { googlePlayUrl, appStoreUrl } from '@/data/mobileApp'

@Component({
  components: {
    Guest,
    GooglePlay,
    AppStore,
  },
})
export default class DownloadApp extends Vue {
  googlePlayUrl = googlePlayUrl
  appStoreUrl = appStoreUrl
}
